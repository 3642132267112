import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/praktijk/',
    name: 'Praktijk',
    component: () => import('../views/Praktijk.vue')
  },
  {
    path: '/behandelingen',
    name: 'Behandelingen',
    component: () => import('../views/Behandelingen.vue')
  },
  {
    path: '/contact/',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
